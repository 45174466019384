/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import localForage from 'localforage';
import store from './store';
const updateStoreOnLocalStorage = async (storeIndex, updatedData) => {
  const storeInstance = await store();
  const userInfo =
    storeInstance.getters['user'] || (await localForage.getItem('userInfo'));
  userInfo.user.store[storeIndex] = {
    ...userInfo.user.store[storeIndex],
    ...updatedData,
  };
  await storeInstance.dispatch('userInfo/loadInfo', userInfo);
  localStorage.setItem(
    'packageInformation',
    JSON.stringify(userInfo.user.packageInformation)
  );
};

const mutations = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
    state.isVerticalNavMenuActive = value;
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val;
  },
  UPDATE_MAIN_LAYOUT_TYPE(state, val) {
    state.mainLayoutType = val;
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
    state.verticalNavMenuItemsMin = val;
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
    state.verticalNavMenuWidth = width;
  },

  // VxAutoSuggest

  UPDATE_STARRED_PAGE(state, payload) {
    // find item index in search list state
    const index = state.navbarSearchAndPinList['pages'].data.findIndex(
      (item) => item.url === payload.url
    );

    // update the main list
    state.navbarSearchAndPinList['pages'].data[index].is_bookmarked =
      payload.val;

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(
        state.navbarSearchAndPinList['pages'].data[index]
      );
    } else {
      // find item index from starred pages
      const index = state.starredPages.findIndex(
        (item) => item.url === payload.url
      );

      // remove item using index
      state.starredPages.splice(index, 1);
    }
  },

  // Navbar-Vertical

  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10);
    state.starredPages = list.concat(starredPagesMore);
  },
  ARRANGE_STARRED_PAGES_MORE(state, list) {
    let downToUp = false;
    const lastItemInStarredLimited = state.starredPages[10];
    const starredPagesLimited = state.starredPages.slice(0, 10);
    state.starredPages = starredPagesLimited.concat(list);

    state.starredPages.slice(0, 10).map((i) => {
      if (list.indexOf(i) > -1) downToUp = true;
    });

    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited);
    }
  },

  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  TOGGLE_CONTENT_OVERLAY(state, val) {
    state.bodyOverlay = val;
  },
  UPDATE_PRIMARY_COLOR(state, val) {
    state.themePrimaryColor = val;
  },
  UPDATE_THEME(state, val) {
    document.cookie = `themeMode=${val}; path=/; domain=eva.guru; secure; SameSite=Strict`;
    state.theme = val;
  },
  UPDATE_WINDOW_WIDTH(state, width) {
    state.windowWidth = width;
  },
  UPDATE_WINDOW_SCROLL_Y(state, val) {
    state.scrollY = val;
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  // Updates user info in state and localstorage
  UPDATE_TRIAL_INFO(state, payload) {
    // Get Data localStorage
    const trialInfo =
      JSON.parse(localStorage.getItem('trial')) || state.AppActiveStoreTrial;

    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveStoreTrial[property] = payload[property];

        // Update key in localStorage
        trialInfo[property] = payload[property];
      }
    }
    // Store data in localStorage
    localStorage.setItem('trial', JSON.stringify(trialInfo));

    // market place choose and appActiveUser info change
    state.AppActiveStoreTrial = trialInfo;
  },

  // Updates user info in state and localstorage
  UPDATE_FUND_INFO(state, payload) {
    // Get Data localStorage
    const fundInfo = JSON.parse(localStorage.getItem('funding')) || {};

    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveStoreFunding[property] = payload[property];

        // Update key in localStorage
        fundInfo[property] = payload[property];
      }
    }
    // Store data in localStorage
    localStorage.setItem('funding', JSON.stringify(fundInfo));

    // market place choose and appActiveUser info change
    state.AppActiveStoreFunding = fundInfo;
  },

  // Updates user info in state and localstorage
  UPDATE_LOAN_INFO(state, payload) {
    // Get Data localStorage
    const loanInfo = JSON.parse(localStorage.getItem('loan')) || {};

    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveStoreLoan[property] = payload[property];

        // Update key in localStorage
        loanInfo[property] = payload[property];
      }
    }
    // Store data in localStorage
    localStorage.setItem('loan', JSON.stringify(loanInfo));

    // market place choose and appActiveUser info change
    state.AppActiveStoreLoan = loanInfo;
  },

  // Updates user info in state and localstorage
  async UPDATE_USER_INFO(state, payload) {
    // Get Data localStorage
    const userInfo =
      (await localForage.getItem('userInfo')) || state.AppActiveUser;

    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property];

        // Update key in localStorage
        userInfo[property] = payload[property];
      }
    }
    var storeList = userInfo.user.store;
    var orderInventorySeriesArr = [];
    var json_str = JSON.stringify(orderInventorySeriesArr);

    for (let i in storeList) {
      storeList[i]['parentAsinJobs'] = {};
      if (
        storeList[i]['showSellerCentralExternalLink'] === undefined ||
        storeList[i]['showSellerCentralExternalLink'] === null ||
        storeList[i]['showSellerCentralExternalLink'] === ''
      ) {
        storeList[i]['showSellerCentralExternalLink'] = true;
      }
    }

    storeList.sort(function (a, b) {
      if (a.storeName < b.storeName) {
        return -1;
      }
      if (a.storeName > b.storeName) {
        return 1;
      }
      return 0;
    });

    userInfo.user.store = storeList;
    // Store data in localStorage

    // set Display Banner in Local storage
    /*if(localStorage.getItem('displayBanner') == null)
       localStorage.setItem('displayBanner', true);*/
    state.userInfo.userInfo = userInfo;
    await localForage.setItem('userInfo', userInfo);
    if (
      JSON.parse(localStorage.getItem('userInventoryChartVisibility')) === null
    ) {
      localStorage.setItem('userInventoryChartVisibility', json_str);
    }

    /*    const accountType = state.selectedStore
      ? state.selectedStore.accountType
      : userInfo?.user?.accountType || 0;

    userInfo.user.packages =
      accountType === 0
        ? userInfo?.user?.allPackages?.AllPackages?.sellerPackageList || []
        : userInfo?.user?.allPackages?.AllPackages?.agencyPackageList || [];*/

    state.AppActiveUser = { ...userInfo };
  },

  // Updates user info in state and localstorage
  UPDATE_DOWNLOAD_INFO(state, payload) {
    // Get Data localStorage
    const downloadInfo =
      JSON.parse(localStorage.getItem('downloadInfo')) ||
      state.AppActiveDownload;

    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveDownload[property] = payload[property];

        // Update key in localStorage
        downloadInfo[property] = payload[property];
      }
    }
    // Store data in localStorage
    localStorage.setItem('downloadInfo', JSON.stringify(downloadInfo));

    // market place choose and appActiveUser info change
    state.AppActiveDownload = downloadInfo;
  },

  // Updates user info in state and localstorage
  UPDATE_UPLOAD_INFO(state, payload) {
    // Get Data localStorage
    const uploadInfo =
      JSON.parse(localStorage.getItem('uploadInfo')) || state.AppActiveUpload;

    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUpload[property] = payload[property];

        // Update key in localStorage
        uploadInfo[property] = payload[property];
      }
    }
    // Store data in localStorage
    localStorage.setItem('uploadInfo', JSON.stringify(uploadInfo));

    // market place choose and appActiveUser info change
    state.AppActiveUpload = uploadInfo;
  },

  // Updates user info in state and localstorage
  UPDATE_EXPORT_INFO(state, payload) {
    // Get Data localStorage
    const exportInfo =
      JSON.parse(localStorage.getItem('exportInfo')) || state.AppActiveExport;

    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveExport[property] = payload[property];

        // Update key in localStorage
        exportInfo[property] = payload[property];
      }
    }
    // Store data in localStorage
    localStorage.setItem('exportInfo', JSON.stringify(exportInfo));

    // market place choose and appActiveUser info change
    state.AppActiveExport = exportInfo;
  },
  UPDATE_EXPORT_REPLENISHMENT_INFO(state, payload) {
    // Get Data localStorage
    const exportInfo =
      JSON.parse(localStorage.getItem('exportReplenishmentInfo')) ||
      state.AppActiveReplenishmentExport;

    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveReplenishmentExport[property] = payload[property];

        // Update key in localStorage
        exportInfo[property] = payload[property];
      }
    }
    // Store data in localStorage
    localStorage.setItem('exportReplenishmentInfo', JSON.stringify(exportInfo));

    // market place choose and appActiveUser info change
    state.AppActiveReplenishmentExport = exportInfo;
  },

  UPDATE_DISPLAY_BANNER(state, payload) {
    state.isBannerShown = payload;
    localStorage.setItem('isBannerShown', payload);
  },

  SET_SELECTED_STORE(state, payload) {
    state.selectedStore = { ...payload };
  },
  UPDATE_USER_AND_STORE_INFO(state, payload) {
    state.AppActiveUser.user = payload.userInfo.user;
    state.AppActiveUser.isLinkAccount = payload.userInfo.isLinkAccount;

    const currentUserInfo = state.AppActiveUser;
    const currentStoreList = [...currentUserInfo.user.store];
    const storeList = payload.userInfo.user.store;

    for (let i = 0; i < currentStoreList.length; i++) {
      for (const [key] of Object.entries(currentStoreList[i])) {
        const payloadStore = storeList.find(
          (store) =>
            store.storeId === currentStoreList[i].storeId &&
            store.marketplaceName === currentStoreList[i].marketplaceName
        );

        if (
          payloadStore !== null &&
          payloadStore !== undefined &&
          payloadStore[key] !== null &&
          payloadStore[key] !== undefined &&
          currentStoreList[i][key] !== payloadStore[key] &&
          key !== ''
        ) {
          currentStoreList[i][key] = payloadStore[key];
        }
      }
    }

    const updatedPayload = {
      ...payload.userInfo,
      user: { ...payload.userInfo.user, store: currentStoreList },
    };
    state.userInfo.userInfo = updatedPayload;
    localForage.setItem('userInfo', updatedPayload).then(() => {
      localStorage.setItem(
        'packageInformation',
        JSON.stringify(updatedPayload.user.packageInformation)
      );
      if (payload.willReload) {
        payload.reloadHandler();
      }
    });
  },

  UPDATE_VERSIONS(state, payload) {
    if (payload.tokenVersion) {
      state.version.tokenVersion = payload.tokenVersion;
      localStorage.setItem('appTokenVersion', payload.tokenVersion);
    }

    if (payload.cssVersion) {
      state.version.cssVersion = payload.cssVersion;
      localStorage.setItem('appCssVersion', payload.cssVersion);
    }
  },
  UPDATE_UPGRADE_YOUR_PLAN(state, payload) {
    state.upgradeYourPlan = payload;
  },
  CLEAR_APP_ACTIVE_USER(state) {
    state.AppActiveUser = {};
  },
  CLEAR_APP_ACTIVE_STORE_TRIAL(state) {
    state.AppActiveStoreTrial = {};
  },
  UPDATE_MARKETPLACE_TIME_ZONE(state, payload) {
    state.marketplaceTimeZone = payload;
    localStorage.setItem('marketplaceTimeZone', JSON.stringify(payload));
  },
  SET_SUCCESSFULLY_MARKETPLACES_LIST(state, payload) {
    state.successfullyMarketplacesList = payload;
  },
  SET_SELECTED_ACCOUNT_LEVEL_EXPORT(state, payload) {
    state.isSelectedAccountLevelExport = payload;
  },
  SET_STORE_TENANT_ID(state, val) {
    state.storeTenantID = val;
  },
  SET_IS_LANGUAGE_PREVIEW(state, val) {
    state.isLanguagePreview = val;
  },
  SET_PREVIOUS_ROUTE(state, val) {
    state.previousRoute = val;
  },
  SET_CONNECT_ACCOUNT_REQUESTED(state, isConnectAccountRequested) {
    state.isConnectAccountRequested = isConnectAccountRequested;
  },
  SET_ADVERTISING_PROMPT(state, isAdvertisingPrompt) {
    state.isAdvertisingPrompt = isAdvertisingPrompt;
  },
  SET_IS_SHOW_FREE_TRIAL_BANNER(state, payload) {
    state.isShowFreeTrialBanner = payload;
  },
  SET_REPRICER_USABLE(state, payload) {
    state.repricerUsable = payload;
  },
  SET_DATA_STATUS_OBJECT(state, payload) {
    state.dataStatusObject = payload;
  },
  SET_DATA_STATUS_ALL_DATA_DOWNLOADED(state, payload) {
    state.dataStatusAllDataDownloaded = payload;
  },
  SET_SETTINGS_DEFAULT_TAB_ID(state, payload) {
    state.settingsDefaultTabId = payload;
  },
};

export default mutations;
